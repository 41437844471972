<template>
  <div>
    <SettingsTitle title="Team Settings"/>
    <AccountLine title="Name" :value="teamSettings.name"/>
    <AccountLine title="Bucket Visibility" :value="teamSettings.publiclyVisible"/>
    <div class="w-full flex">
      <div class="w-2/6 border-r border-grey-darker-200 py-5">
        <span class="font-sansDemi base-font-gray-200 text-xl leading-none">Slack Integration</span>
      </div>
      <div class="w-4/6 pl-8 py-5">
        <ul v-if="teamSettings.slackIntegration">
          <div class="base-font-gray-200 text-xl leading-none">Active
          <button 
            @click="deactivateSlack"
            class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm text-black font-monoDemi font-medium ml-4"
          >
            Deactivate
          </button>
          <button 
            onclick="location.href='https://slack.com/oauth/v2/authorize?client_id=1675708259281.3515468304996&scope=incoming-webhook,chat:write&user_scope=';"
            class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm text-black font-monoDemi font-medium ml-4"
          >
            Change Slack Channel
          </button>

          </div>
        </ul>
        <div 
          v-else
          class="flex items-center h-full base-font-gray-200 text-xl leading-none"
        >
          <a href="https://slack.com/oauth/v2/authorize?client_id=1675708259281.3515468304996&scope=incoming-webhook,chat:write&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
        </div>
      </div>
    </div>

        
  </div>
</template>

<script>
  import SettingsTitle from '@/components/settings/SettingsTitle'
  import AccountLine from '@/components/settings/AccountLine'

  export default {
    components: { 
      SettingsTitle,
      AccountLine
    },
    data(){
      return {
        loading: false,
        teamSettings: {
          team: false,
          name: "",
          id: "",
          publiclyVisible: "",
          slackIntegration: false
        }
      }
    },
    methods: {
      async deactivateSlack() {
        const { res } = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/slack-connection`, {
          activate: false
        });

        if (res.response === "deactivated") {
          this.teamSettings.slackIntegration = false;
        }
      }
    },
    async mounted(){
      const { data } = await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-team`);
      if (data.type === "team") {
        console.log(data.type)
        this.teamSettings = {
          team: true,
          name: data.name,
          id: data.team,
          publiclyVisible: data.publiclyVisible ? "Public" : "Private",
          slackIntegration: data.slackActive 
        }

      }
      console.log(data);


      if (this.$route.query.code) {
        const { res } = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/slack-connection`, {
          slackId: this.$route.query.code,
          activate: true
        });

        if (res.response === "activated") {
          this.teamSettings.slackIntegration = true;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>